// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
  }
  
  .App {
    text-align: center;
    padding: 20px;
  }
  
  h1 {
    color: #333;
  }
  
  .game-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  canvas {
    border: 2px solid #333;
    background-color: #000;
  }
  
  .game-info {
    margin-top: 10px;
    font-size: 18px;
  }
  
  .game-over {
    color: red;
    font-weight: bold;
  }
  
  .loading {
    font-size: 24px;
    color: #333;
    margin-top: 50px;
  }`, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,8BAA8B;IAC9B,yBAAyB;EAC3B;;EAEA;IACE,kBAAkB;IAClB,aAAa;EACf;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,sBAAsB;IACtB,sBAAsB;EACxB;;EAEA;IACE,gBAAgB;IAChB,eAAe;EACjB;;EAEA;IACE,UAAU;IACV,iBAAiB;EACnB;;EAEA;IACE,eAAe;IACf,WAAW;IACX,gBAAgB;EAClB","sourcesContent":["body {\n    margin: 0;\n    padding: 0;\n    font-family: Arial, sans-serif;\n    background-color: #f0f0f0;\n  }\n  \n  .App {\n    text-align: center;\n    padding: 20px;\n  }\n  \n  h1 {\n    color: #333;\n  }\n  \n  .game-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-top: 20px;\n  }\n  \n  canvas {\n    border: 2px solid #333;\n    background-color: #000;\n  }\n  \n  .game-info {\n    margin-top: 10px;\n    font-size: 18px;\n  }\n  \n  .game-over {\n    color: red;\n    font-weight: bold;\n  }\n  \n  .loading {\n    font-size: 24px;\n    color: #333;\n    margin-top: 50px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
