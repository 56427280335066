import React, { useRef, useEffect, useState, useCallback } from 'react';
import { initializeGame } from '../utils/gameState';
import { updateGameState, movePlayer } from '../utils/gameLogic';
import { preloadAssets, getSprite, drawRotatedSprite } from '../utils/sprites';
import { CANVAS_WIDTH, CANVAS_HEIGHT, GRID_SIZE, ROWS, COLS, BOTTOM_SAFE_ZONE_ROWS, MIDDLE_SAFE_ZONE_ROWS, TOP_SAFE_ZONE_ROWS } from '../utils/constants';
import useGameLoop from '../hooks/useGameLoop';

function Game() {
  const canvasRef = useRef(null);
  const [gameState, setGameState] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [spritesLoaded, setSpritesLoaded] = useState(false);

  useEffect(() => {
    async function setup() {
      console.log('Setting up game...');
      setIsLoading(true);
      await preloadAssets();
      setSpritesLoaded(true);
      const initialState = initializeGame();
      console.log('Initial game state:', initialState);
      setGameState(initialState);
      setIsLoading(false);
      console.log('Setup complete');
    }
    setup();
  }, []);

  const drawGame = useCallback((ctx) => {
    if (!gameState || !spritesLoaded) {
      console.log('Unable to draw game:', { gameState: !!gameState, spritesLoaded });
      return;
    }

    // Clear the canvas
    ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

    // Draw safe zones
    const safeZoneSprite = getSprite('safezone');
    for (let row = 0; row < BOTTOM_SAFE_ZONE_ROWS; row++) {
      for (let col = 0; col < COLS; col++) {
        ctx.drawImage(safeZoneSprite, col * GRID_SIZE, (ROWS - row - 1) * GRID_SIZE, GRID_SIZE, GRID_SIZE);
      }
    }
    for (let row = 0; row < MIDDLE_SAFE_ZONE_ROWS; row++) {
      for (let col = 0; col < COLS; col++) {
        ctx.drawImage(safeZoneSprite, col * GRID_SIZE, (ROWS - BOTTOM_SAFE_ZONE_ROWS - 5 - row - 1) * GRID_SIZE, GRID_SIZE, GRID_SIZE);
      }
    }
    for (let row = 0; row < TOP_SAFE_ZONE_ROWS; row++) {
      for (let col = 0; col < COLS; col++) {
        ctx.drawImage(safeZoneSprite, col * GRID_SIZE, row * GRID_SIZE, GRID_SIZE, GRID_SIZE);
      }
    }

    // Draw water entities (logs and turtles)
    gameState.waterEntities.forEach(entity => {
      if (entity.type === 'log') {
        const leftSprite = getSprite('log', 'logleft');
        const middleSprite = getSprite('log', 'logmiddle');
        const rightSprite = getSprite('log', 'logright');
        
        const logWidth = entity.width / GRID_SIZE;
        for (let i = 0; i < logWidth; i++) {
          let sprite;
          if (i === 0) {
            sprite = leftSprite;
          } else if (i === logWidth - 1) {
            sprite = rightSprite;
          } else {
            sprite = middleSprite;
          }
          ctx.drawImage(sprite, entity.x + i * GRID_SIZE, entity.y, GRID_SIZE, GRID_SIZE);
        }
      } else if (entity.type === 'turtle') {
        let sprite;
        if (entity.diving) {
          if (entity.diveProgress <= 60) {
            sprite = getSprite('turtle', 'dive1');
          } else {
            sprite = getSprite('turtle', 'dive2');
          }
        } else if (entity.submerged) {
          // Don't render anything when fully submerged
          return;
        } else if (entity.resurfacing) {
          if (entity.resurfaceProgress <= 60) {
            sprite = getSprite('turtle', 'dive2');
          } else {
            sprite = getSprite('turtle', 'dive1');
          }
        } else {
          sprite = getSprite('turtle', `frame${entity.animationFrame + 1}`);
        }

        if (sprite) {
          const rotation = entity.isMovingRight ? Math.PI : 0;
          for (let i = 0; i < entity.width / GRID_SIZE; i++) {
            drawRotatedSprite(
              ctx, 
              sprite, 
              entity.x + i * GRID_SIZE, 
              entity.y, 
              GRID_SIZE, 
              GRID_SIZE, 
              rotation
            );
          }
        }
      }
    });

    // Sort vehicles by y-position and then by x-position to ensure proper rendering order
    const sortedVehicles = [...gameState.vehicles].sort((a, b) => {
      if (a.y === b.y) {
        return a.x - b.x;
      }
      return a.y - b.y;
    });

    // Draw vehicles
    sortedVehicles.forEach(vehicle => {
      if (vehicle.type === 'lorry') {
        const topSprite = getSprite('traffic', 'lorry', 'top');
        const bottomSprite = getSprite('traffic', 'lorry', 'bottom');
        const rotation = vehicle.isMovingRight ? Math.PI : 0;
        if (vehicle.isMovingRight) {
          drawRotatedSprite(ctx, bottomSprite, vehicle.x, vehicle.y, GRID_SIZE, GRID_SIZE, rotation);
          drawRotatedSprite(ctx, topSprite, vehicle.x + GRID_SIZE, vehicle.y, GRID_SIZE, GRID_SIZE, rotation);
        } else {
          drawRotatedSprite(ctx, topSprite, vehicle.x, vehicle.y, GRID_SIZE, GRID_SIZE, rotation);
          drawRotatedSprite(ctx, bottomSprite, vehicle.x + GRID_SIZE, vehicle.y, GRID_SIZE, GRID_SIZE, rotation);
        }
      } else if (vehicle.type === 'car') {
        const sprite = getSprite('traffic', 'car', vehicle.spriteIndex);
        const rotation = vehicle.isMovingRight ? Math.PI : 0;
        drawRotatedSprite(ctx, sprite, vehicle.x, vehicle.y, GRID_SIZE, GRID_SIZE, rotation);
      }
    });

    // Draw player (Frogger)
    const froggerSprite = gameState.player.isMoving ? getSprite('frogger', 'leap') : getSprite('frogger', 'idle');
    ctx.save();
    ctx.translate(
      gameState.player.x + GRID_SIZE / 2,
      gameState.player.y + GRID_SIZE / 2
    );
    let rotation = 0;
    switch (gameState.player.direction) {
      case 'up': rotation = 0; break;
      case 'right': rotation = Math.PI / 2; break;
      case 'down': rotation = Math.PI; break;
      case 'left': rotation = -Math.PI / 2; break;
      default: break;
    }
    ctx.rotate(rotation);
    ctx.drawImage(
      froggerSprite,
      -GRID_SIZE / 2,
      -GRID_SIZE / 2,
      GRID_SIZE,
      GRID_SIZE
    );
    ctx.restore();

  }, [gameState, spritesLoaded]);

  const updateGame = useCallback(() => {
    if (!gameState) {
      console.log('Game state not initialized');
      return;
    }

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Update game state
    const updatedState = updateGameState(gameState);
    setGameState(updatedState);

    // Draw the game
    drawGame(ctx);

  }, [gameState, drawGame]);

  useGameLoop(updateGame, isLoading);

  useEffect(() => {
    function handleKeyDown(event) {
      if (!gameState || !gameState.isAlive || gameState.player.isMoving) return;

      let dx = 0, dy = 0;
      switch(event.key) {
        case 'ArrowUp': dy = -1; break;
        case 'ArrowDown': dy = 1; break;
        case 'ArrowLeft': dx = -1; break;
        case 'ArrowRight': dx = 1; break;
        default: return;
      }
      const newState = movePlayer(gameState, dx, dy);
      setGameState(newState);
    }

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [gameState]);

  if (isLoading) {
    return <div className="loading">Loading game assets...</div>;
  }

  return (
    <div className="game-container">
      <canvas ref={canvasRef} width={CANVAS_WIDTH} height={CANVAS_HEIGHT} />
      <div className="game-info">
        <p>Score: {gameState ? gameState.score : 0}</p>
        {gameState && !gameState.isAlive && <p className="game-over">Game Over! Press any key to restart.</p>}
      </div>
    </div>
  );
}

export default Game;