// Canvas and game grid
export const GRID_SIZE = 40;
export const COLS = 20;
export const ROWS = 16;
export const CANVAS_WIDTH = COLS * GRID_SIZE;
export const CANVAS_HEIGHT = ROWS * GRID_SIZE;

// Game zones
export const BOTTOM_SAFE_ZONE_ROWS = 2;
export const TRAFFIC_ROWS = 5;
export const MIDDLE_SAFE_ZONE_ROWS = 2;
export const WATER_ROWS = 5;
export const TOP_SAFE_ZONE_ROWS = 2;

// Entity properties
export const MIN_LOG_LENGTH = 3;
export const MAX_LOG_LENGTH = 5;
export const MIN_TURTLE_LENGTH = 2;
export const MAX_TURTLE_LENGTH = 4;
export const LORRY_LENGTH = 2;

// Movement speeds
export const MIN_LOG_SPEED = 0.5;
export const MAX_LOG_SPEED = 1.5;
export const MIN_TURTLE_SPEED = 0.25;
export const MAX_TURTLE_SPEED = 0.75;
export const MIN_VEHICLE_SPEED = 0.5;
export const MAX_VEHICLE_SPEED = 1.5;

// Game mechanics
export const FROGGER_MOVE_DURATION = 15; // Frames for frogger to move one grid
export const DEATH_ANIMATION_DURATION = 60; // In frames, assuming 60 FPS

// Probabilities
export const LORRY_PROBABILITY = 0.2; // 20% chance for a lorry, 80% for a car

// Scoring
export const POINT_FOR_FORWARD_MOVE = 10;
export const POINT_FOR_REACHING_TOP = 50;

// Asset paths
export const SPRITE_PATH = '/assets/sprites';
export const SOUNDS_PATH = '/assets/sounds'