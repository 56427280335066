import { useEffect, useRef } from 'react';

function useGameLoop(updateFunction, isPaused = false) {
  const requestRef = useRef();
  const previousTimeRef = useRef();

  useEffect(() => {
    if (isPaused) {
      return;
    }

    const animate = time => {
      if (previousTimeRef.current !== undefined) {
        const deltaTime = time - previousTimeRef.current;
        updateFunction(deltaTime);
      }
      previousTimeRef.current = time;
      requestRef.current = requestAnimationFrame(animate);
    }

    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, [updateFunction, isPaused]);
}

export default useGameLoop;