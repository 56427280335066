import { GRID_SIZE, COLS, ROWS, FROGGER_MOVE_DURATION, WATER_ROWS, TOP_SAFE_ZONE_ROWS } from './constants';
import { nextLevel } from './gameState';

// Create a new Audio object for the hop sound
const hopSound = new Audio('/assets/Sounds/sound-frogger-hop.wav');

export function updateGameState(gameState) {
    if (!gameState.isAlive) {
        return gameState;
    }

    gameState = updatePlayerMovement(gameState);
    gameState = updateWaterEntities(gameState);
    gameState = updateVehicles(gameState);
    gameState = checkCollisions(gameState);
    gameState = checkWinCondition(gameState);

    return gameState;
}

function updatePlayerMovement(gameState) {
    if (gameState.player.isMoving) {
        gameState.player.moveProgress += 1;
        if (gameState.player.moveProgress >= FROGGER_MOVE_DURATION) {
            gameState.player.isMoving = false;
            gameState.player.moveProgress = 0;
        }
    }
    return gameState;
}

function updateWaterEntities(gameState) {
    gameState.waterEntities = gameState.waterEntities.map(entity => {
        entity.x += entity.speed;
        if (entity.x > COLS * GRID_SIZE) {
            entity.x = -entity.width;
        } else if (entity.x + entity.width < 0) {
            entity.x = COLS * GRID_SIZE;
        }

        if (entity.type === 'turtle') {
            const currentTime = Date.now();
            const animationDuration = 120; // 2 seconds at 60 fps

            if (!entity.diving && !entity.submerged && !entity.resurfacing && currentTime - entity.lastDiveTime > entity.diveDelay) {
                entity.diving = true;
                entity.diveProgress = 0;
            }

            if (entity.diving) {
                entity.diveProgress += 1;
                if (entity.diveProgress >= animationDuration) {
                    entity.diving = false;
                    entity.submerged = true;
                    entity.submergeTime = currentTime;
                }
            } else if (entity.submerged) {
                if (currentTime - entity.submergeTime > 1000) { // Stay submerged for 1 second
                    entity.submerged = false;
                    entity.resurfacing = true;
                    entity.resurfaceProgress = 0;
                }
            } else if (entity.resurfacing) {
                entity.resurfaceProgress += 1;
                if (entity.resurfaceProgress >= animationDuration) {
                    entity.resurfacing = false;
                    entity.lastDiveTime = currentTime;
                    entity.diveDelay = Math.random() * 10000 + 5000; // Reset dive delay
                }
            } else {
                // Normal swimming animation
                entity.animationFrame = (entity.animationFrame + 1) % 3;
            }
        }

        return entity;
    });
    return gameState;
}

function updateVehicles(gameState) {
    gameState.vehicles = gameState.vehicles.map(vehicle => {
        vehicle.x += vehicle.speed;
        if (vehicle.x > COLS * GRID_SIZE) {
            vehicle.x = -vehicle.width;
        } else if (vehicle.x + vehicle.width < 0) {
            vehicle.x = COLS * GRID_SIZE;
        }
        return vehicle;
    });
    return gameState;
}

function checkCollisions(gameState) {
    const player = gameState.player;
    
    // Check collision with vehicles
    const collidedWithVehicle = gameState.vehicles.some(vehicle =>
        player.x < vehicle.x + vehicle.width &&
        player.x + player.width > vehicle.x &&
        player.y < vehicle.y + vehicle.height &&
        player.y + player.height > vehicle.y
    );

    if (collidedWithVehicle) {
        gameState.isAlive = false;
        return gameState;
    }

    // Check if in water
    const playerRow = Math.floor(player.y / GRID_SIZE);
    const isInWater = playerRow >= TOP_SAFE_ZONE_ROWS && 
                      playerRow < (TOP_SAFE_ZONE_ROWS + WATER_ROWS);

    if (isInWater) {
        const onSafeEntity = gameState.waterEntities.some(entity =>
            player.x >= entity.x &&
            player.x < entity.x + entity.width &&
            player.y === entity.y &&
            !(entity.type === 'turtle' && (entity.submerged || (entity.diving && entity.diveProgress > 60) || (entity.resurfacing && entity.resurface < 60)))
        );

        if (!onSafeEntity) {
            gameState.isAlive = false;
            return gameState;
        } else {
            // Move player with the log/turtle
            const entity = gameState.waterEntities.find(e => 
                player.x >= e.x && player.x < e.x + e.width && player.y === e.y
            );
            player.x += entity.speed;

            // Check if player has moved off the screen
            if (player.x < 0 || player.x + player.width > COLS * GRID_SIZE) {
                gameState.isAlive = false;
                return gameState;
            }
        }
    }

    return gameState;
}

function checkWinCondition(gameState) {
    const playerRow = Math.floor(gameState.player.y / GRID_SIZE);
    if (playerRow === 0) {
        return nextLevel(gameState);
    }
    return gameState;
}

export function movePlayer(gameState, dx, dy) {
    if (gameState.player.isMoving) {
        return gameState;
    }

    const newX = gameState.player.x + dx * GRID_SIZE;
    const newY = gameState.player.y + dy * GRID_SIZE;

    if (newX >= 0 && newX < COLS * GRID_SIZE && newY >= 0 && newY < ROWS * GRID_SIZE) {
        gameState.player.x = newX;
        gameState.player.y = newY;
        gameState.player.isMoving = true;
        gameState.player.moveProgress = 0;

        if (dx > 0) gameState.player.direction = 'right';
        else if (dx < 0) gameState.player.direction = 'left';
        else if (dy < 0) gameState.player.direction = 'up';
        else if (dy > 0) gameState.player.direction = 'down';

        // Add points for forward movement
        if (dy < 0) {
            gameState.score += 10;
        }

        // Play the hop sound
        playHopSound();
    }

    function playHopSound() {
        hopSound.pause();
        hopSound.currentTime = 0;
        hopSound.play().catch(error => console.error('Error playing sound:', error));
    }

    return gameState;
}

