import { 
    GRID_SIZE, COLS, TRAFFIC_ROWS, WATER_ROWS, 
    LORRY_LENGTH, MIN_LOG_SPEED, MAX_LOG_SPEED, MIN_TURTLE_SPEED, MAX_TURTLE_SPEED, 
    MIN_VEHICLE_SPEED, MAX_VEHICLE_SPEED, LORRY_PROBABILITY,
    TOP_SAFE_ZONE_ROWS, MIDDLE_SAFE_ZONE_ROWS
} from './constants';

export function createWaterEntities() {
    let entities = [];
    for (let row = 0; row < WATER_ROWS; row++) {
        const isLogRow = row % 2 === 0; // Even rows are logs, odd rows are turtles
        const speed = isLogRow 
            ? (Math.random() * (MAX_LOG_SPEED - MIN_LOG_SPEED) + MIN_LOG_SPEED) * (Math.random() < 0.5 ? 1 : -1)
            : (Math.random() * (MAX_TURTLE_SPEED - MIN_TURTLE_SPEED) + MIN_TURTLE_SPEED) * (Math.random() < 0.5 ? 1 : -1);
        
        let col = 0;
        
        while (col < COLS) {
            if (isLogRow) {
                const logLength = Math.floor(Math.random() * 3) + 3; // 3, 4, or 5 blocks
                
                // Check if there's enough space for the log and the minimum gap
                if (col + logLength + 3 <= COLS) {
                    entities.push({
                        x: col * GRID_SIZE,
                        y: (TOP_SAFE_ZONE_ROWS + row) * GRID_SIZE,
                        width: logLength * GRID_SIZE,
                        height: GRID_SIZE,
                        speed: speed,
                        isMovingRight: speed > 0,
                        type: 'log'
                    });
                    
                    // Move the column position past the log and the minimum gap
                    col += logLength + 3;
                    
                    // Add a random additional gap (0 to 2 blocks)
                    col += Math.floor(Math.random() * 3);
                } else {
                    // Not enough space for another log, move to next row
                    break;
                }
            } else {
                const turtleGroupSize = Math.floor(Math.random() * 3) + 2; // 2, 3, or 4 turtles in a group
                
                // Check if there's enough space for the turtle group and the minimum gap
                if (col + turtleGroupSize + 2 <= COLS) {
                    entities.push({
                        x: col * GRID_SIZE,
                        y: (TOP_SAFE_ZONE_ROWS + row) * GRID_SIZE,
                        width: turtleGroupSize * GRID_SIZE,
                        height: GRID_SIZE,
                        speed: speed,
                        isMovingRight: speed > 0,
                        type: 'turtle',
                        animationFrame: 0,
                        diving: false,
                        diveProgress: 0,
                        diveDelay: Math.random() * 10000 + 5000,
                        lastDiveTime: Date.now()
                    });
                    
                    // Move the column position past the turtle group and the minimum gap
                    col += turtleGroupSize + 2;
                    
                    // Add a random additional gap (0 to 2 blocks)
                    col += Math.floor(Math.random() * 3);
                } else {
                    // Not enough space for another turtle group, move to next row
                    break;
                }
            }
        }
    }
    return entities;
}

export function createTraffic() {
    let traffic = [];
    for (let row = 0; row < TRAFFIC_ROWS; row++) {
        let speed = (Math.random() * (MAX_VEHICLE_SPEED - MIN_VEHICLE_SPEED) + MIN_VEHICLE_SPEED) * (Math.random() < 0.5 ? 1 : -1);
        let col = 0;
        while (col < COLS) {
            let isLorry = Math.random() < LORRY_PROBABILITY;
            let vehicleWidth = isLorry ? LORRY_LENGTH : 1;
            let spriteIndex = Math.floor(Math.random() * 4); // Randomly select car sprite

            traffic.push({
                x: col * GRID_SIZE,
                y: (TOP_SAFE_ZONE_ROWS + WATER_ROWS + MIDDLE_SAFE_ZONE_ROWS + row) * GRID_SIZE,
                width: vehicleWidth * GRID_SIZE,
                height: GRID_SIZE,
                speed: speed,
                isMovingRight: speed > 0,
                type: isLorry ? 'lorry' : 'car',
                spriteIndex: spriteIndex
            });
            
            let spacing = Math.max(2, Math.floor(Math.random() * 3) + 2); // Minimum 2 spaces, up to 4
            col += vehicleWidth + spacing;
        }
    }
    return traffic;
}