import { SPRITE_PATH } from './constants';

// Define the sprite objects
export const sprites = {
    frogger: {
        idle: null,
        leap: null
    },
    traffic: {
        cars: [null, null, null, null],
        lorry: {
            top: null,
            bottom: null
        }
    },
    log: {
        left: null,
        middle: null,
        right: null
    },
    turtle: {
        frame1: null,
        frame2: null,
        frame3: null,
        dive1: null,
        dive2: null
    },
    safezone: null
};

// Helper function to load an image
function loadImage(src) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = (error) => reject({ src, error });
        img.src = `${SPRITE_PATH}/${src}`;
    });
}

// Function to preload all assets
export async function preloadAssets() {
    const spriteFiles = [
        { name: 'FroggerIdle.png', target: sprites.frogger, key: 'idle' },
        { name: 'FroggerLeap.png', target: sprites.frogger, key: 'leap' },
        ...Array(4).fill().map((_, i) => ({ name: `traffic/car${i+1}.png`, target: sprites.traffic.cars, key: i })),
        { name: 'traffic/lorrytop.png', target: sprites.traffic.lorry, key: 'top' },
        { name: 'traffic/lorrybottom.png', target: sprites.traffic.lorry, key: 'bottom' },
        { name: 'logs/log_left.png', target: sprites.log, key: 'logleft' },
        { name: 'logs/log_middle.png', target: sprites.log, key: 'logmiddle' },
        { name: 'logs/log_right.png', target: sprites.log, key: 'logright' },
        { name: 'turtles/turtle_frame1.png', target: sprites.turtle, key: 'frame1' },
        { name: 'turtles/turtle_frame2.png', target: sprites.turtle, key: 'frame2' },
        { name: 'turtles/turtle_frame3.png', target: sprites.turtle, key: 'frame3' },
        { name: 'turtles/turtle_dive_1.png', target: sprites.turtle, key: 'dive1' },
        { name: 'turtles/turtle_dive_2.png', target: sprites.turtle, key: 'dive2' },
        { name: 'safezone.png', target: sprites, key: 'safezone' }
    ];

    const loadPromises = spriteFiles.map(file => 
        loadImage(file.name)
            .then(img => file.target[file.key] = img)
            .catch(error => {
                console.error(`Failed to load sprite: ${file.name}`, error);
                throw error;
            })
    );

    try {
        await Promise.all(loadPromises);
        console.log('All sprites loaded successfully');
    } catch (error) {
        console.error('Error loading sprites:', error);
    }
}

// Function to get a sprite
export function getSprite(type, variant, index) {
    if (type === 'frogger' || type === 'log') {
        return sprites[type][variant];
    } else if (type === 'traffic') {
        if (variant === 'car') {
            return sprites.traffic.cars[index % 4];
        } else if (variant === 'lorry') {
            return sprites.traffic.lorry[index]; // 'top' or 'bottom'
        }
    } else if (type === 'safezone') {
        return sprites.safezone;
    } else if (type === 'turtle') {
        return sprites.turtle[variant];
    }
    return null;
}

// Function to draw a rotated sprite
export function drawRotatedSprite(ctx, sprite, x, y, width, height, rotation) {
    ctx.save();
    ctx.translate(x + width / 2, y + height / 2);
    ctx.rotate(rotation);
    ctx.drawImage(sprite, -width / 2, -height / 2, width, height);
    ctx.restore();
}