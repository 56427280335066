import { GRID_SIZE, COLS, ROWS, BOTTOM_SAFE_ZONE_ROWS } from './constants';
import { createWaterEntities, createTraffic } from './entityCreation';

export function initializeGame() {
    return {
        player: {
            x: Math.floor(COLS / 2) * GRID_SIZE,
            y: (ROWS - BOTTOM_SAFE_ZONE_ROWS) * GRID_SIZE,
            width: GRID_SIZE,
            height: GRID_SIZE,
            isMoving: false,
            moveProgress: 0,
            direction: 'up'
        },
        waterEntities: createWaterEntities(),
        vehicles: createTraffic(),
        score: 0,
        isAlive: true,
        level: 1
    };
}

export function resetPlayer(gameState) {
    gameState.player = {
        ...gameState.player,
        x: Math.floor(COLS / 2) * GRID_SIZE,
        y: (ROWS - BOTTOM_SAFE_ZONE_ROWS) * GRID_SIZE,
        isMoving: false,
        moveProgress: 0,
        direction: 'up'
    };
    return gameState;
}

export function nextLevel(gameState) {
    gameState.level += 1;
    gameState.score += 50; // Bonus for completing a level
    gameState = resetPlayer(gameState);
    gameState.waterEntities = createWaterEntities();
    gameState.vehicles = createTraffic();
    return gameState;
}